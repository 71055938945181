@charset 'UTF-8';

@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}
@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

@include key-frame("fade-up") {
  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@include key-frame("fadeIn") {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include key-frame("fadeInLeft") {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@include key-frame("scroll-d") {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -2101px 0;
  }
}
@include SP() {
  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -300vw 0;
    }
  }
}

[data-inview-ani="fadeUp"] {
  opacity: 0;
  &.is-inview {
    animation-name: fade-up;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}


[data-inview-ani="fadeIn"] {
  opacity: 0;
  &.is-inview {
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}

[data-inview-ani="fadeInLeft"] {
  opacity: 0;
  &.is-inview {
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}