@charset 'UTF-8';
.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  padding: 0;
  background: #fff;
  @include add_prefix(transition, 0.3s ease all);
  @include SP() {
    height: 64px;
  }
  .inHeader {
    position: relative;
    z-index: 2000;
    @include PC() {
      @include flexbox;
      @include justify-content(flex-end);
      @include flex-wrap;
      @include align-items(flex-start);
    }
  }
  #logo {
    position: absolute;
    left: 27px;
    top: 20px;
    z-index: 1000;
    @include add_prefix(transition, 0.3s ease all);
    @include flexbox;
    @include flex-wrap;
    @include align-items();
    @include SP() {
      left: 23px;
      top: 15px;
    }
    img{
      @include SP() {
        width: 137px;
      }
    }
    .hd_top{
      padding-left: 26px;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: .08em;
    }
  }
}

.top{
  @include SP() {
    padding-top: 0;
  }
  &:not(.fixHeader){
    .header{
      background: transparent;
    }
  }
}

.fixHeader {
  background-color: #fff;
}

.c-fix{
  @include flexbox;
  @include justify-content(flex-end);
  width: 354px;
  margin-left: 43px;
  @include SP() {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-left: 0;
    transition: all .3s;
    transform: translateY(100%);
    background-color: #fff;
    z-index: 10;
    @include justify-content(space-between);
    &.fix_bottom{
      transform: translateY(0);
    }
  }
  a{
    width: calc(33.333% - 2px);
    height: 62px;
    color: #fff;
    @include flexbox;
    @include justify-content();
    @include align-items();
    font-size: 14px;
    line-height: 34px;
    letter-spacing: .1em;
    background-color: $blue;
    @include SP() {
      height: 60px;
    }
    &:not(:last-child){
      margin-right: 2px;
    }
    &.bgGreen{
      background-color: $green;
    }
    &.bgBrown{
      background-color: $brown;
    }
  }
}

.layerMenu {
  text-align: right;
  position: relative;
  @include PC() {
    @include flexbox;
    @include justify-content();
    @include flex-wrap;
    @include align-items();
    min-height: 80px;
  }
  @include SP() {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    text-align: left;
    background: #66837B;
    width: 100%;
    color: #fff;
    padding: 18px 20px;
  }
  .inLayer {
    @include PC() {
      @include flexbox;
      @include justify-content(flex-end);
      @include flex-wrap;
      @include align-items();
    }
    @include SP() {
      position: relative;
      height: 100%;
      overflow: auto;
      padding-bottom: 40px;
    }
  }
  .gNavi {
    @include flexbox;
    @include justify-content(flex-end);
    @include flex-wrap;
    margin-bottom: 4px;
    @include SP() {
      margin-bottom: 0;
      margin-top: 20px;
      @include justify-content();
    }
    > li {
      display: inline-block;
      vertical-align: top;
      width: auto;
      position: relative;
      margin-left: 26px;
      @include ipad {
        margin-left: 15px;
      }
      @include SP() {
        display: block;
        width: calc(50% - 5px);
        margin: 0 0 10px;
        &:not(.ml0){
          margin-right: 10px;
        }
      }
      > a {
        display: block;
        padding: 3px 0;
        position: relative;
        font-size: 14px;
        letter-spacing: .15em;
        @include SP() {
          background-color: #fff;
          border-radius: 4px;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0;
          font-weight: 500;
          color: #000;
          padding: 14px;
          span{
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16'%3E%3Cg id='Group_35997' data-name='Group 35997' transform='translate(-0.5 0)'%3E%3Cellipse id='Ellipse_640' data-name='Ellipse 640' cx='7.5' cy='8' rx='7.5' ry='8' transform='translate(0.5 0)' fill='%23d7e2df'/%3E%3Cpath id='Path_94023' data-name='Path 94023' d='M0,0H4.831V4.831' transform='translate(6.555 4.412) rotate(45)' fill='none' stroke='%2366837b' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center right;
            width: 100%;
          }
          img{
            margin-bottom: 12px;
          }
        }
      }
      &.gNavi_100{
        width: 100%;
        margin: 0 0 10px;
        > a{
          @include flexbox;
          @include justify-content();
          @include flex-wrap;
          @include align-items();
          height: 68px;
          text-align: center;
          font-size: 16px;
          line-height: 32px;
          letter-spacing: .1em;
          span{
            background-position: center right 12px;
          }
        }
      }
      &.gNavi_mt30{
        @include SP() {
          margin-top: 20px;
        }
      }
    }
    .navSub{
      padding: 10px 28px 28px;
      li{
        border-bottom: 1px solid #A1B5B0;
        a{
          display: block;
          color: #fff;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: .05em;
          font-weight: 500;
          padding: 15px 15px 15px 0;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.503' height='10.503' viewBox='0 0 10.503 10.503'%3E%3Cpath id='Path_93695' data-name='Path 93695' d='M0,0H6.927V6.927' transform='translate(4.898 0.354) rotate(45)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center right 8px;
        }
      }
    }
  }
  .hd_tel{
    @include SP() {
      &__tt{
        text-align: center;
        color: #fff;
        letter-spacing: .3em;
        font-weight: 500;
      }
      .c-tel{
        margin-top: 20px;
      }
      .c-btn{
        display: block;
        max-width: 217px;
        margin: 0 auto 32px;
        padding: 0 40px 10px 8px;
      }
    }
  }
  .hd_btn{
    @include SP() {
      a{
        color: #fff;
        @include flexbox;
        @include justify-content();
        @include align-items();
        letter-spacing: .15em;
        background-color: $blue;
        height: 61px;
        text-align: center;
        &:not(:last-child){
          margin-bottom: 10px;
        }
        &.bgGreen{
          background-color: $green;
        }
        &.bgBrown{
          background-color: $brown;
        }
      }
    }
  }
  .c-menu{
    @include SP() {
      padding: 55px 0 60px;
    }
  }
  .close_layer01{
    @include SP() {
      position: absolute;
      right: 7px;
      top: 0;
      font-size: 0;
      width: 25px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: top center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.078' height='23.844' viewBox='0 0 25.078 23.844'%3E%3Cg id='Group_36009' data-name='Group 36009' transform='translate(-323.156 -27.137)'%3E%3Cline id='Line_30' data-name='Line 30' x2='24.39' y2='23.118' transform='translate(323.5 27.5)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3Cline id='Line_31' data-name='Line 31' y1='23.118' x2='24.39' transform='translate(323.5 27.5)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
  .close_layer02 {
    display: none;
    @include SP() {
      display: block;
      text-align: center;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: .025em;
      font-weight: 500;
      font-family: $roboto;
      padding-top: 21px;
      text-decoration: underline;
      background-repeat: no-repeat;
      background-position: top center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.127' height='18.203' viewBox='0 0 19.127 18.203'%3E%3Cg id='Group_36010' data-name='Group 36010' transform='translate(-323.156 -27.137)'%3E%3Cline id='Line_30' data-name='Line 30' x2='18.439' y2='17.478' transform='translate(323.5 27.5)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3Cline id='Line_31' data-name='Line 31' y1='17.478' x2='18.439' transform='translate(323.5 27.5)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.layerOn {
  //overflow: hidden;
  .layerMenu {
    right: 0;
  }
}

.hamberger {
  position: fixed;
  right: 23px;
  top: 17px;
  z-index: 1000;
  width: 32px;
  height: 32px;
  padding: 9px 0 0;
  line-height: 1;
  font-size: 0;
  text-align: center;
  cursor: pointer;
  display: none;
  span {
    display: inline-block;
    width: 32px;
    height: 1px;
    margin-bottom: 8px;
    background: #000;
    @include add_prefix(transition, 0.3s ease all);
  }
  &.active {
    // span {
    //   width: 33px;
    //   background-color: #fff;
    // }
    // .ham { @include transform_c(rotate(45deg) translateY(7px) translateX(7px)); }
    // .ber { @include transform_c(rotate(-45deg)); }
    // .ger { display: none; }
  }
  @include max-screen(767px) {
    display: block;
  }
}