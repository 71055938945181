@charset 'UTF-8';

.c-lazybg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

[rel~="js-lazy"] {
  opacity: 0;
  &:not(.initial) {
    transition: opacity 0.3s;
  }
  &.initial,
  &.loaded,
  &.error,
  &.entered {
    opacity: 1;
  }
}

a.c-btn,.c-btn {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .2em;
  font-weight: 500;
  position: relative;
  border-bottom: 1px solid #000;
  padding: 0 57px 21px 19px;
  @include SP() {
    padding: 0 51px 20px 21px;
  }
  span {
    display: inline-block;
  }
  &:after{
    transition: all .3s;
    content: '';
    position: absolute;
    right: 12px;
    top: 2px;
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.503' height='10.503' viewBox='0 0 10.503 10.503'%3E%3Cpath id='Path_93695' data-name='Path 93695' d='M2695,1066.5h6.927v6.927' transform='translate(-1146.625 -2659.428) rotate(45)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E%0A");
    @include SP() {
      right: 15px;
    }
  }
  &--white{
    color: #fff;
    border-bottom-color: #fff;
    &:after{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.503' height='10.503' viewBox='0 0 10.503 10.503'%3E%3Cpath id='Path_93695' data-name='Path 93695' d='M0,0H6.927V6.927' transform='translate(4.898 0.354) rotate(45)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A");
    }
  }
  &--small{
    @include SP() {
      max-width: 176px;
      padding-left: 12px;
      margin: 0 auto;
      display: block;
    }
  }
  &--backlist{
    padding-left: 50px;
    padding-right: 25px;
    &:after{
      right: auto;
      transform: scaleX(-1);
      left: 25px;
    }
    &:hover{
      @include HOVER {
         &:after{
          left: 20px;
         }
      }
    }
  }
  &:hover{
    @include HOVER {
      opacity: .75;
      &:after{
        right: 10px;
      }
    }
  }
}

a.c-btn02,.c-btn02{
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .2em;
  font-weight: 500;
  text-align: center;
  border: 1px solid #000;
  @include flexbox;
  @include justify-content();
  @include align-items();
  height: 55px;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    right: 28px;
    top: 50%;
    margin-top: -8px;
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.607' height='10.607' viewBox='0 0 10.607 10.607'%3E%3Cpath id='Path_113190' data-name='Path 113190' d='M0,0H7V7' transform='translate(10.253 4.95) rotate(135)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E%0A");
    @include SP() {
      right: 15px;
      margin-top: -6px;
    }
  }
}

.c-social{
  position: absolute;
  right: 32px;
  bottom: 22px;
  z-index: 2;
  @include flexbox;
  @include justify-content();
  @include flex-wrap;
  @include align-items(flex-start);
  @include flex-direction();
  a{
    display: block;
    text-align: center;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .025em;
    font-weight: 500;
    font-family: $roboto;
    color: #fff;
    &:not(:last-child){
      margin-bottom: 6px;
    }
    span{
      display: block;
      height: 13px;
    }
  }
}

.c-title{
  position: relative;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: .3em;
  font-weight: 500;
  @include SP() {
    font-size: 16px;
    line-height: 20px;
  }
  &__img{
  }
  &--white{
    color: #fff;
  }
  &--left{
    text-align: left;
  }
  &__ja{
    @include PC() {
      margin-top: 14px;
    }
  }
}

.c-events-list{
  @include flexbox;
  @include flex-wrap;
  .c-events-item{
    width: calc(25% - 24px);
    @include SP() {
      width: calc(50% - 5px);
      &:not(:nth-child(2n)){
        margin-right: 10px;
      }
      &:not(:nth-child(-n+2)){
        margin-top: 25px;
      }
    }
    &:not(:nth-child(4n)){
      @include PC() {
        margin-right: 32px;
      }
    }

    &__img{
      display: block;
      @include aspect-ratio(232, 232);
      margin-bottom: 15px;
      @include SP() {
        margin-bottom: 12px;
      }
    }
    &__date{
      color: $brown2;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: .08em;
      display: block;
      font-family: $roboto;
      margin-bottom: 6px;
      @include SP() {
        margin-bottom: 4px;
      }
    }
    &__tt{
      line-height: 28px;
      letter-spacing: .025em;
      margin-bottom: 15px;
      @include SP() {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 7px;
      }
    }
    &__time,&__end{
      display: none;
    }
    &__add{
      position: relative;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: .05em;
      display: block;
      padding-left: 17px;
      background-repeat: no-repeat;
      background-position: left 5px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12' height='16' viewBox='0 0 12 16'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_14345' data-name='Rectangle 14345' width='12' height='16' fill='%23958e71'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_27143' data-name='Group 27143' transform='translate(0 0)'%3E%3Cg id='Group_27140' data-name='Group 27140' transform='translate(0 0)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_93704' data-name='Path 93704' d='M6,0A5.992,5.992,0,0,0,1.133,9.5l.157.22L5.6,15.8a.5.5,0,0,0,.8,0l4.256-6.009.21-.291A5.992,5.992,0,0,0,6,0M6,8A2,2,0,1,1,8,6,2,2,0,0,1,6,8' transform='translate(0 0)' fill='%23958e71'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.c-works{
  &__img{
    @include aspect-ratio(360, 480);
    position: relative;
    margin-bottom: 10px;
  }
  &__cate{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    padding: 7px 14px;
    @include SP() {
      font-size: 10px;
      line-height: 23px;
      letter-spacing: .08em;
      padding: 0 8px;
    }
  }
  &__tt{
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    @include SP() {
      letter-spacing: .08em;
    }
  }
  &__desc{
    font-size: 12px;
    line-height: 20px;
    letter-spacing: .15em;
    @include SP() {
      font-size: 10px;
    }
  }
}

.c-reqcon{
  @include flexbox;
  @include flex-wrap;
  @include flexbox;
  @include justify-content();
  position: relative;
  padding-bottom: 227px;
  margin-bottom: 0;
  @include SP() {
    padding-bottom: 112px;
    display: block;
  }
  &__bg{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
  }
  .c-reqcon-item{
    position: relative;
    color: #fff;
    z-index: 1;
    text-align: center;
    padding-bottom: 67px;
    @include PC() {
      width: 50%;
    }
    @include SP() {
      text-align: right;
      padding: 0 20px 25px;
    }
    &:before{
      content: '';
      position: absolute;
      top: 76px;
      left: 0;
      right: 0;
      bottom: 40px;
      background-color: $green2;
      z-index: -1;
      @include SP() {
        top: 0;
        bottom: 0;
      }
    }
    &__img{
      @include PC() {
        min-height: 280px;
        margin-bottom: 4px;
      }
      @include SP() {
        width: 180px;
        position: absolute;
        right: 8px;
        top: -39px;
      }
    }
    &__tt{
      margin-bottom: 27px;
      @include SP() {
        padding-top: 32px;
        text-align: left;
        margin-bottom: 24px;
      }
      .ttJa{
        font-size: 24px;
        letter-spacing: .3em;
        font-weight: 500;
        margin-bottom: 15px;
        @include SP() {
          margin-bottom: 13px;
        }
      }
      .ttEn{
        font-size: 14px;
        line-height: 19px;
        letter-spacing: .1em;
        font-family: $roboto;
        font-weight: 500;
        @include SP() {
          font-size: 10px;
          line-height: 13px;
          opacity: .5;
          padding-left: 3px;
        }
      }
    }
    &__desc{
      letter-spacing: .15em;
      font-weight: 500;
      margin-bottom: 52px;
      @include SP() {
        margin-bottom: 13px;
        line-height: 32px;
        text-align: left;
      }
    }
    &__list{
      text-align: left;
      display: inline-block;
      @include SP() {
        display: block;
        margin-bottom: 17px;
      }
      li{
        letter-spacing: .15em;
        font-weight: 500;
        position: relative;
        padding-left: 25px;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16.78' height='16' viewBox='0 0 16.78 16'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_15721' data-name='Rectangle 15721' width='16.78' height='12.624' transform='translate(0 0)' fill='%23e39360'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_27224' data-name='Group 27224' transform='translate(-835 -7177)'%3E%3Cg id='Rectangle_15717' data-name='Rectangle 15717' transform='translate(835 7180)' fill='none' stroke='%23fff' stroke-width='1'%3E%3Crect width='13' height='13' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='12' height='12' fill='none'/%3E%3C/g%3E%3Cg id='Group_27223' data-name='Group 27223' transform='translate(835 7177)'%3E%3Cg id='Group_27222' data-name='Group 27222' transform='translate(0 0)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_93928' data-name='Path 93928' d='M5.007,12.587c-.417-.221-.3-.828-.689-1.759a12.255,12.255,0,0,0-1.2-2.376C1.242,6.158,0,6.27,0,5.932S.853,5.117,1.359,5a2.073,2.073,0,0,1,1.576.258A5.121,5.121,0,0,1,4.189,6.395c.423.553,1.145,1.532,1.145,1.532a28.96,28.96,0,0,1,8.59-6.9A14.648,14.648,0,0,1,15.418.318,4.524,4.524,0,0,1,16.78,0,61.569,61.569,0,0,0,7.214,10.07c-.233.318-.908,1.419-1.273,1.871-.243.3-.6.825-.933.646' transform='translate(0 0)' fill='%23e39360'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        @include SP() {
          font-size: 14px;
          line-height: 32px;
          padding-left: 24px;
        }
      }
    }
    .c-btn{
      @include PC() {
        display: block;
        margin: 38px auto 0;
        width: 176px;
        padding-right: 40px;
        padding-left: 16px;
        padding-bottom: 21px;
      }
      @include SP() {
        margin-right: -4px;
      }
    }
    &--02{
      @include SP() {
        margin-top: 3px;
      }
      &:before{
        background-color: #958E71;
        bottom: 0;
        top: 110px;
        @include SP() {
          top: 0;
        }
      }
      .c-reqcon-item__tt{
        margin-bottom: 15px;
        @include PC() {
          margin-bottom: 24px;
        }
      }
      .c-reqcon-item__img{
        @include SP() {
          top: -14px;
          // right: -10px;
          right: 0;
          width: 200px;
        }
      }
    }
  }
}

.c-pagename {
  position: relative;
  max-width: 1360px;
  margin: 0 auto;
  @include PC {
    padding: 221px 21px 65px 180px;
    @include flexbox();
    @include justify-content(space-between);
  }
  @include SP {
    padding: 88px 0 30px;
  }
  &:before {
    content: "";
    width: 270px;
    height: 270px;
    background-image: url(../img/common/pagename_deco01.png);
    background-size: 270px 270px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 124px;
    left: -62px;
    @include SP {
      width: 180px;
      height: 180px;
      background-image: url(../img/common/pagename_deco01_sp.png);
      background-size: 180px 180px;
      top: 15px;
      left: -72px;
    }
  }
  &__hd {}
  &__ttl-en {
    position: absolute;
    top: 81px;
    left: 54px;
    z-index: 1;
    @include PC {
      img {
        max-width: none;
      }
    }
    @include SP {
      width: 245px;
      top: -6px;
      left: 4px;
    }
  }
  &__ttl-ja {
    @include font(16, 26px, .4em, 500);
    position: relative;
    z-index: 2;
    @include SP {
      text-align: center;
      padding-left: 8px;
      @include font(14, 24px);
    }
  }
  &__breadcrumb {
    position: relative;
    @include PC {
      padding-top: 3px;
      max-width: calc(100% - 300px);
    }
    @include SP {
      margin-top: 66px;
      padding: 0 10px 0 20px;
    }
  }
}

.c-breadcrumb {
  @include font(10, 12px, .08em, 500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  li {
    display: inline;
    &:not(:first-child) {
      position: relative;
      padding-left: 20px;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-top: 1px solid #333834;
        border-right: 1px solid #333834;
        position: absolute;
        top: 6px;
        left: 3px;
        @include transform_c(rotate(45deg));
      }
    }
  }
  a, span {
    color: #333834;
  }
  a {
    text-decoration: underline;
  }
}

.c-tel{
  display: inline-block;
  margin: 37px 0 39px;
  font-weight: 500;
  font-family: $roboto;
  text-align: center;
  @include SP() {
    display: block;
    background-color: rgba(255, 255, 255, .7);
    border-radius: 10px;
    text-align: center;
    padding: 24px 20px 20px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.503' height='10.503' viewBox='0 0 10.503 10.503'%3E%3Cpath id='Path_94016' data-name='Path 94016' d='M0,0H6.927V6.927' transform='translate(4.898 0.354) rotate(45)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 15px center;
    margin: 23px 0 37px;
  }
  .txtTel{
    display: inline-block;
    position: relative;
    padding-left: 41px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url("data:image/svg+xml,%3Csvg id='Group_27212' data-name='Group 27212' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='38.272' height='21.898' viewBox='0 0 38.272 21.898'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_15710' data-name='Rectangle 15710' width='38.272' height='21.898' transform='translate(0 0)' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_27211' data-name='Group 27211' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_93923' data-name='Path 93923' d='M34.131,19.826a2.071,2.071,0,1,0,2.07-2.07,2.073,2.073,0,0,0-2.07,2.07m.24,0a1.831,1.831,0,1,1,1.83,1.83,1.833,1.833,0,0,1-1.83-1.83' fill='%231a1311'/%3E%3Cpath id='Path_93924' data-name='Path 93924' d='M37.209,21.167h-.4l-.8-1.243h-.353v1.243h-.319V18.431h.775a.926.926,0,0,1,.616.189.648.648,0,0,1,.226.526.672.672,0,0,1-.165.446.847.847,0,0,1-.454.265Zm-1.554-1.52h.4a.6.6,0,0,0,.41-.137.44.44,0,0,0,.157-.345q0-.458-.558-.457h-.41Z' fill='%231a1311'/%3E%3Cpath id='Path_93925' data-name='Path 93925' d='M20.811,12.471a4.077,4.077,0,1,0,8.146,0c0-2.226-2.2-4-4.073-5.066-1.871,1.063-4.073,2.84-4.073,5.066' fill='%231a1311'/%3E%3Cpath id='Path_93926' data-name='Path 93926' d='M4.252,12.519a4.078,4.078,0,1,0,8.148,0c0-2.224-2.2-4-4.074-5.065C6.455,8.517,4.252,10.3,4.252,12.519' fill='%231a1311'/%3E%3Cpath id='Path_93927' data-name='Path 93927' d='M0,21.626H33.137V0H0ZM33.132,5.259a16.757,16.757,0,0,0-6.306,1.2c2.048,1.369,4.027,3.443,4.027,6.062a6.154,6.154,0,0,1-6.1,6.1,6.072,6.072,0,0,1-6-6.1c0-2.619,1.883-4.693,3.93-6.062a15.829,15.829,0,0,0-6.12-1.2,15.83,15.83,0,0,0-6.121,1.2c2.048,1.369,3.929,3.443,3.929,6.062a6.07,6.07,0,0,1-6,6.1,6.192,6.192,0,0,1-6.144-6.1C2.228,9.9,4.254,7.824,6.3,6.455A16.727,16.727,0,0,0,0,5.259V3.365A18.652,18.652,0,0,1,8.372,5.258a17.838,17.838,0,0,1,8.192-1.893,17.84,17.84,0,0,1,8.191,1.893,18.676,18.676,0,0,1,8.377-1.893Z' fill='%231a1311'/%3E%3C/g%3E%3C/svg%3E%0A");
    font-size: 32px;
    line-height: 43px;
    letter-spacing: 0em;
    @include SP() {
      font-size: 29px;
      line-height: 38px;
      background-size: 36px 21px;
      padding-left: 38px;
    }
  }
  .txtTime{
    font-size: 14px;
    line-height: 19px;
    letter-spacing: .05em;
    display: block;
    margin-top: 1px;
    @include SP() {
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;
    }
    .fz12 {
      font-size: 12px;
      line-height: 16px;
      @include SP() {
        font-size: 10px;
      }
    }
  }
}
.wp-pagenavi {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include font(14, 24px, .1em, 500);
  color: #2B2B2B;
  font-family: $roboto;
  .pages {
    display: none;
  }
  .page, .current, .first, .last {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    white-space: nowrap;
    margin: 0 1px;
  }
  .page, .first, .last {
    @include HOVER {
      &:hover {
        opacity: 1;
        background-color: #F5F5F5;
      }
    }
  }
  .current {
    background-color: #D7E2DF;
  }
  .extend {
    white-space: nowrap;
    letter-spacing: 0;
    margin: 0 15px;
  }
  .previouspostslink,
  .nextpostslink {
    letter-spacing: .05em;
    position: relative;
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 6px;
      transition: 0.3s;
    }
  }
  .previouspostslink {
    margin-right: 60px;
    order: -1;
    padding-left: 24px;
    &:before {
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(13.189 12.393) rotate(180)'%3E%3Ccircle cx='6' cy='6' r='6' transform='translate(1.189 0.393)'/%3E%3Cpath class='a' d='M0,4.892V0H4.892' transform='translate(9.039 6.086) rotate(135)'/%3E%3C/g%3E%3C/svg%3E");
    }
    @include HOVER {
      &:hover {
        &:before {
          @include transform_c(translateX(-5px));
        }
      }
    }
  }
  .nextpostslink {
    margin-left: 60px;
    order: 1;
    padding-right: 24px;
    &:before {
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-1.189 -0.393)'%3E%3Ccircle cx='6' cy='6' r='6' transform='translate(1.189 0.393)'/%3E%3Cpath class='a' d='M0,4.892V0H4.892' transform='translate(9.039 6.086) rotate(135)'/%3E%3C/g%3E%3C/svg%3E");
    }
    @include HOVER {
      &:hover {
        &:before {
          @include transform_c(translateX(5px));
        }
      }
    }
  }
}

.wp-selectpage {
  margin: 0 auto;
  position: relative;
  max-width: 288px;
  font-family: $roboto;
  .pages {
    margin: 0 auto;
    width: 111px;
    height: 55px;
    border: 1px solid #4A4A4A;
    position: relative;
    select {
      display: block;
      width: 100%;
      height: 100%;
      @include add_prefix(appearance, none);
      border-radius: 0;
      box-shadow: none;
      border: none;
      background: none;
      text-align: center;
      @include font(18, 28px, .1em, 500);
    }
    .current {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include font(18, 28px, .1em, 500);
      white-space: nowrap;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 9 9'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23000;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M.235,5.52,0,0,5.52.235' transform='translate(4.288 7.837) rotate(-135)'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center right 16px;
      padding-right: 17px;
      background-color: #fff;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
  .previouspostslink,
  .nextpostslink {
    letter-spacing: .05em;
    position: relative;
    @include font(14, 24px, .05em, 500);
    position: absolute;
    top: 15px;
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 6px;
      transition: 0.3s;
    }
  }
  .previouspostslink {
    left: 0;
    padding-left: 22px;
    &:before {
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(13.189 12.393) rotate(180)'%3E%3Ccircle cx='6' cy='6' r='6' transform='translate(1.189 0.393)'/%3E%3Cpath class='a' d='M0,4.892V0H4.892' transform='translate(9.039 6.086) rotate(135)'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
  .nextpostslink {
    right: 0;
    padding-right: 22px;
    &:before {
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-1.189 -0.393)'%3E%3Ccircle cx='6' cy='6' r='6' transform='translate(1.189 0.393)'/%3E%3Cpath class='a' d='M0,4.892V0H4.892' transform='translate(9.039 6.086) rotate(135)'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}