@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

// CUSTOM

.leftCol {
  width:70%;
  float:left;
}
.rightCol {
  width:30%;
  float:right;
}

.cmsContent {
  @include clearfix;
  -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    word-wrap: break-word;
  h2 {
    @include font(24, 44px, .15em, 500);
    padding-bottom: 26px;
    border-bottom: 1px solid #000;
    margin: 46px 0 36px;
    @include SP {
      @include font(20, 36px, .1em);
      padding-bottom: 20px;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  h3 {
    @include font(18, 34px, .1em, 500);
    background-color: #F5F4F2;
    padding: 20px 26px;
    margin: 40px 0 30px;
    @include SP {
      line-height: 30px;
      letter-spacing: .15em;
      padding: 23px 20px;
      margin: 40px -20px 30px;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  h4 {
    @include font(18, 34px, .15em, 500);
    color: #27725E;
    padding-bottom: 20px;
    border-bottom: 1px solid #27725E;
    margin: 40px 0 30px;
    @include SP {
      line-height: 28px;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  p {
    margin-bottom: 32px;
  }
  a {
    color: #008EC3;
    text-decoration: underline;
  }
  blockquote {
    margin: 40px 0 20px;
    display: block;
    border: 1px solid #D7E2DF;
    padding: 35px 30px 27px 57px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='37.444' height='29.808' viewBox='0 0 37.444 29.808'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23e2e0d7;%7D.b%7Bopacity:0.4;%7D.c%7Bclip-path:url(%23a);%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='37.444' height='29.808'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b' transform='translate(37.444 29.808) rotate(180)'%3E%3Cg class='c'%3E%3Cpath class='a' d='M17.773,2.224c-2.744,2-7.754,5.673-7.754,8.787,0,1.446.955,1.78,2.267,2.225a8.622,8.622,0,0,1,5.964,8.008,8.862,8.862,0,0,1-9.065,8.564C4.294,29.808,0,26.249,0,21.133,0,12.124,8.231,4.893,15.984,0Zm19.194,0c-2.743,2-7.873,5.673-7.873,8.787,0,1.446.954,1.78,2.385,2.225a8.622,8.622,0,0,1,5.964,8.008c0,4.671-4.174,8.564-9.184,8.564-4.891,0-9.184-3.559-9.184-8.676,0-9.009,8.23-16.24,15.984-21.133Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: top 23px left 30px;
    min-height: 75px;
    @include SP {
      margin: 40px -12px 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30.721' height='24.457' viewBox='0 0 30.721 24.457'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23e2e0d7;%7D.b%7Bopacity:0.4;%7D.c%7Bclip-path:url(%23a);%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='30.721' height='24.457'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b' transform='translate(30.721 24.457) rotate(180)'%3E%3Cg class='c'%3E%3Cpath class='a' d='M14.582,1.825C12.331,3.467,8.221,6.479,8.221,9.034c0,1.186.783,1.46,1.86,1.825a7.074,7.074,0,0,1,4.893,6.57,7.271,7.271,0,0,1-7.437,7.027A7.257,7.257,0,0,1,0,17.338C0,9.947,6.753,4.015,13.115,0Zm15.748,0c-2.25,1.643-6.459,4.654-6.459,7.209,0,1.186.783,1.46,1.957,1.825a7.074,7.074,0,0,1,4.893,6.57,7.359,7.359,0,0,1-7.536,7.027,7.257,7.257,0,0,1-7.536-7.118C15.65,9.947,22.4,4.015,28.764,0Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-position: top 13px left 15px;
      padding: 28px 18px 25px 20px;
      @include font(14, 26px, .08em);
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  ul, ol {
    margin: 46px 0 10px;
    @include font(16, 30px, .08em, 500);
    &:nth-child(1) {
      margin-top: 0;
    }
    li {
      padding-left: 25px;
      position: relative;
      @include SP {
        padding-left: 18px;
      }
    }
    li + li {
      margin-top: 7px;
    }
  }
  ul {
    li {
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100px;
        background-color: #27725E;
        position: absolute;
        top: 11px;
        left: 0;
      }
    }
  }
  ol {
    counter-reset: counter;
    li {
      counter-increment: counter;
      &:before {
        content: counter(counter, decimal)".";
        color: #27725E;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  span[style*="text-decoration: underline"] {
    font-style: normal;
    text-decoration: none !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bclip-path:url(%23a);%7D.b%7Bfill:%23fff186;opacity:0.7;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect width='20' height='20'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='a'%3E%3Crect class='b' width='434' height='6' transform='translate(-162 14)'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 20px 20px;
    background-position: left bottom;
    background-repeat: repeat-x;
  }
  iframe {
    max-width: 100%!important;
  }
  img {
    max-width:100%;
    height:auto;
    max-height:100%;
    width: auto;
  }
  .setW {
    @include PC {
      width: 420px;
      height: 630px;
      object-fit: cover;
      margin-left: 0;
      margin-right: 5px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.pagingDt {
  position:relative;
  width:80%;
  margin:95px auto;
  font-size:14px;
}
.pagingDt a {
  text-decoration:underline;
}
.pagingDt .prev {
  background:url(../../img/blog/ico_prev.png) no-repeat left center;
  width:145px;
  float:left;
  padding-left:20px;
}
.pagingDt .next {
  background:url(../../img/blog/ico_next.png) no-repeat right center;
  width:145px;
  float:right;
  padding-right:20px;
}
.pagingDt .list {
  position:absolute;
  left:50%;
  top:50%;
  margin-left:-54px;
  margin-top:-9px;
}
