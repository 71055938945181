@charset 'UTF-8';
.c-menu{
  background-color: $green2;
  color: #fff;
  padding: 110px 22px 45px;
  text-align: center;
  &__logo{
    margin-bottom: 34px;
    display: inline-block;
  }
  .c-social{
    position: relative;
    @include flex-direction(row);
    right: auto;
    bottom: auto;
    margin-bottom: 42px;
    a{
      margin: 0 10px;
      font-size: 12px;
      line-height: 16px;
      img{
        width: 56px;
        height: 56px;
        margin-bottom: 4px;
      }
      span{
        height: auto;
      }
    }
  }
  .c-menu-store{
    @include flexbox;
    @include flex-wrap;
    text-align: left;
    &__item{
      width: calc(50% - 6px);
      position: relative;
      &:not(:nth-child(2n)){
        margin-right: 12px;
      }
    }
    &__img{
      @include aspect-ratio(160, 92);
      margin-bottom: 10px;
      @include SP() {
        margin-bottom: 13px;
      }
    }
    &__tt{
      font-size: 16px;
      line-height: 19px;
      letter-spacing: .2em;
      font-weight: 500;
      margin-bottom: 6px;
      @include SP() {
        margin-bottom: 5px;
      }
    }
    &__add{
      font-size: 12px;
      line-height: 20px;
      letter-spacing: .1em;
      font-weight: 500;
    }
    &__map{
      position: absolute;
      right: 8px;
      bottom: 2px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: .025em;
      font-weight: 500;
      color: #fff;
      font-family: $roboto;
      display: inline-block;
      padding-top: 20px;
      background-image: url("data:image/svg+xml,%3Csvg id='Group_27382' data-name='Group 27382' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12' height='18.501' viewBox='0 0 12 18.501'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_15786' data-name='Rectangle 15786' width='12' height='18.501' transform='translate(0 0)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_27381' data-name='Group 27381' transform='translate(0 0)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_94019' data-name='Path 94019' d='M6,0A5.965,5.965,0,0,0,0,5.93C0,11.118,6,18.5,6,18.5s6-7.29,6-12.571A5.965,5.965,0,0,0,6,0M6,8.471A2.824,2.824,0,1,1,8.824,5.647,2.824,2.824,0,0,1,6,8.471' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
}
a.totop{
  position: absolute;
  right: 32px;
  top: 70px;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: .05em;
  font-weight: 500;
  font-family: $roboto;
  display: inline-block;
  @include SP() {
    position: absolute;
    bottom: -43px;
    top: auto;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  &:before{
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 1px;
    background-color: #000;
    height: 14px;
    transition: all .3s;
    @include SP() {
      background-color: #fff;
      height: 20px;
      top: -27px;
    }
  }
  &:hover{
    @include HOVER {
      opacity: 1;
      &:before{
        top: -28px;
      }
    }
  }
}
.footer{
  position: relative;
  @include SP() {
    padding-bottom: 60px;
  }
  &__decor{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -131px;
    @include SP() {
      top: -80px;
    }
  }
  &__decor2{
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &__ctn{
    padding: 0 28px 53px;
    @include SP() {
      position: relative;
    }
    @include PC() {
      padding: 35px 74px 40px;
      @include flexbox;
      @include flex-wrap;
      // @include align-items();
      max-width: 1360px;
      margin: 0 auto;
    }
  }
  &__info{
    position: relative;
    @include PC() {
      // width: 35%;
      max-width: 428px;
      width: 428px;
      border: 4px solid #fff;
      padding: 48px 30px 63px;
      @include flexbox;
      @include justify-content();
      @include flex-wrap;
      @include align-items();
      @include flex-direction();
    }
  }
  .c-title{
    @include PC() {
      position: unset;
    }
    @include SP() {
      text-align: left;
    }
    &__img{
      transform: rotate(-2deg);
      @include PC() {
        position: absolute;
        left: 5px;
        top: -128px;
        margin-right: 0;
      }
      @include SP() {
        width: 219px;
        margin-top: -40px;
        margin-left: -5px;
      }
    }
    &__ja{
      @include SP() {
        text-align: center;
        margin-top: 10px;
        margin-top: -20px;
      }
    }
  }
  &__nav{
    // width: 65%;
    padding-left: 114px;
    @include flexbox;
    @include flex-wrap;
    padding-top: 56px;
    > ul{
      &:not(:last-child){
        margin-right: 30px;
      }
      &.nav_col1{
        margin-right: 40px;
      }
      a{
        display: block;
        font-weight: 500;
        letter-spacing: .1em;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: $green2;
        }
      }
      > li{
        > a{
          font-size: 14px;
          padding-left: 15px;
          &:before{
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
        }
      }
      ul{
        padding-left: 19px;
        li{
          a{
            padding-left: 13px;
            font-size: 12px;
            line-height: 24px;
            &:before{
              width: 6px;
              height: 1px;
              transform: none;
            }
          }
        }
      }
    }
  }
  .c-btn{
    @include SP() {
      display: block;
      max-width: 217px;
      margin: 0 auto;
      padding: 0 40px 21px 8px;
    }
  }
  .c-social{
    @include PC() {
      bottom: 32px;
    }
  }
  &__copyright{
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .05em;
    font-weight: 500;
    @include PC() {
      position: absolute;
      transform: rotate(-90deg);
      transform-origin: left top;
      left: 29px;
      bottom: 20px;
    }
    @include SP() {
      background-color: #EDF0EF;
      text-align: center;
      color: $green2;
      font-size: 12px;
      line-height: 32px;
      padding-bottom: 3px;
    }
    span{
      font-family: $roboto;
    }
  }
}